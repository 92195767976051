<template>
  <div id="notification-list">

    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="value => { listQuery = value; getList() }"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list"
      :row-class-name="tableRowClassName"
      @row-click="readNotification"
    >
      <el-table-column
        prop="id"
        :label="$t('TIME')"
        width="150"
      >
        <template slot-scope="{row}">
          {{ parseDate(row.createdDate) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('DETAILS')"
        :width="isMobile ? '500' : ''"
      >
        <template slot-scope="{ row }">
          {{ parseDetail(row.body) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="100"
      >
        <template slot-scope="{row}">
          <div>
            <span>{{ $t(row.status) }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import { generalMixin } from "@/utils/general-mixin.js"
import { EventBus } from "@/utils/event-bus";
import cloneDeep from "lodash/cloneDeep"
import { PAGE_SIZES } from "@/constants";
import { getNotificationList, readNotification } from "@/api/notification"

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  dateFrom: "",
  dateTo: ""
};

export default {
  name: "NotificationList",
  mixins: [ generalMixin ],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      isLoading: false,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      total: 0,
      list: [],
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    tableRowClassName({ row }) {
      if (row.status === 'UNREAD') {
        return 'unread-row'
      }
      return ''
    },
    getList() {
      this.isLoading = true
      getNotificationList(this.listQuery)
      .then(res => {
        if (res.status != 'ok') return
        console.log('Got: ', res)
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
    readNotification(row) {
      if (row.status == "UNREAD") {
        this.isLoading = true
        readNotification({ id: row.id })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false
          this.getList()
          this.$store.dispatch("notification/checkNotification") // refresh
        })
      }
    },
    parseDetail(body) {
      let result = ""
      const regStart = "%TRANSLATE_"
      const regEnd = "_TRANSLATE%"

      const splittedByEnd = body.split(regEnd)
      if (splittedByEnd.length > 1) { // need translation from FE
        splittedByEnd.forEach(str => {
          const splittedByStart = str.split(regStart)
          const codeToTranslate = splittedByStart[1]
          result += splittedByStart[0]
          if (codeToTranslate) {
            result += this.$t(codeToTranslate)
          }
        })
      } else { // no need translation from FE
        result = body
      }

      return result
    }
  },
  mounted() {
    this.getList();
    (EventBus || this.$EventBus).$on("on-language-change", () => this.getList());
  },
  beforeDestroy() {
    (EventBus || this.$EventBus).$off("on-language-change");
  }
}
</script>

<style lang="scss">
@import "@/assets/style/mixin/table.scss";
#notification-list {
  position: relative;
  @include newsily-bo-table;
  .icon-edit {
    float: right;
  }
  .unread-row {
    cursor: pointer;
    background-color: #FEF8EF;
    border: 1px solid #FCEDDB;
    color: #CC8738;
  }
}
</style>