
<!--
Will only display and return the correct pagination UI (No filtering here)

@pagination will return the current page number and limit per page
:total must be set

How to use:
<pagination
  v-show="total > 0"
  :page.sync="listQuery.page"
  :limit.sync="listQuery.limit"
  :total="total"
  @pagination="handleCurrentChange"
/> 
-->
<template>
  <div class="pagination-container" :class="{ hidden: hidden }">
    <el-pagination
      :layout="isMobile ? 'sizes, prev, pager, next' : layout"
      :current-page.sync="currentPage"
      :total="total"
      :page-size.sync="pageSize"
      :page-sizes="pageSizes"
      :small="isMobile"
      :pager-count="isMobile ? 5 : pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :prev-text="$t('PREV')"
      :next-text="$t('NEXT')"
    >
    </el-pagination>
  </div>
</template>

<script>
import { PAGE_SIZES } from "@/constants";
import { generalMixin } from '@/utils/general-mixin.js';
export default {
  name: "Pagination",
  mixins: [generalMixin],
  props: {
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next",
    },
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      // default set when mounted if limit is null
    },
    pageSizes: {
      type: Array,
      default() {
        return PAGE_SIZES;
      },
    },
    pagerCount: {
      type: Number,
      default: 7
    }
  },
  methods: {
    handleCurrentChange(val) {
      // console.log('current-changed')
      this.$emit("pagination", { page: val, limit: this.pageSize });
    },
    handleSizeChange(val) {
      // console.log('size-changed')
      this.$emit("pagination", { page: this.currentPage, limit: val });
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  mounted() { },
};
</script>

<style lang="scss">
// not id since this pagination might be used
// multiple times in the same page
.pagination-container {
  background: #F3F5FA 0% 0% no-repeat padding-box;

  &.hidden {
    display: none;
  }
  .el-pagination {
    margin: 10px 0;
    width: 100%;
    display: flex;
    .el-pagination__sizes {
      flex-grow: 2
    }
    >button, // prev/next button
    >ul * { // pager
      background-color: transparent;
    }
  }
}
.mobile {
  .pagination-container {
    overflow-x: scroll;
  }
}
</style>
