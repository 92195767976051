<template>

<!-- 
  Please add position: relative; to the most outer part of the parent,
  if the positioning of this component is wrong after transition
 -->

  <el-row 
    type="flex" 
    :justify="isMobile ? 'start' : 'end'"
    class="table-action-row"
    :class="isPositionAbsolute ? ' position-absolute' : ''" 
  >

    <div v-if="showSearch && isMobile" class="bg-primary search-button" @click="handleFilterChange()"> <!-- (Mobile) Search at left -->
      <i class="custom-icon icon-header-search white-icon mini pointer"/>
    </div>
    <el-input 
      v-model="searchKeyword" 
      class="toggleable-search pointer" 
      :class="showSearch ? 'show' : 'hide'"
      @keyup.enter.native="handleFilterChange"
      @click.native="!showSearch ? toggleSearch() : null"
    >
      <template slot="prefix" v-if="!showSearch">
        <i class="custom-icon icon-search mini grey-icon pointer"/>
      </template>
      <template slot="suffix" v-if="showSearch">
        <i class="custom-icon icon-clear mini grey-icon pointer" @click.stop="toggleSearch()"/>
      </template>
    </el-input>
    <div v-if="showSearch && !isMobile" class="bg-primary search-button" @click="handleFilterChange()"> <!-- (Web) Search at right -->
      <i class="custom-icon icon-header-search white-icon mini pointer"/>
    </div>

    <div v-if="isMobile && canFilterDate" class="bg-white toggle-date-picker-button" @click="toggleDatePicker()"> <!-- (Mobile) Toggle date picker -->
      <i class="custom-icon icon-calendar mini pointer"/>
    </div>
    <el-date-picker
      v-if="canFilterDate"
      v-model="dateRange"
      :class="showDatePicker ? 'show' : 'hide'"
      :size="isMobile ? 'mini' : null"
      type="daterange"
      placement="bottom-start"
      :editable="false"
      :range-separator="$t('TO')"
      :start-placeholder="$t('START_DATE')"
      :end-placeholder="$t('END_DATE')"
      prefix-icon="custom-icon icon-calendar mini"
      :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
      @focus="e => e.blur()"
    />

    <el-popover
      v-if="filterKeyNameList != null"
      placement="bottom-end"
      trigger="click"
      popper-class="filter-popover"
    >
      <div slot="reference" class="bg-white filter-button pointer" @click.stop="$emit('toggleFilter')">
        <el-badge class="filter-badge" :value="activeFilterNum" :hidden="activeFilterNum < 1">
          <i class="custom-icon icon-filter mini"/>
        </el-badge>
      </div>
      <slot name="filter-popover-inner"/>
    </el-popover>

    <div v-if="isMobile" class="middle-padding"/>

    <el-button 
      v-if="handleAction != null" 
      class="button black action-button" 
      :class="isMobile ? '' : 'with-icon icon-spaced'"
      @click="handleAction"
    >
      <i v-if="actionButtonIcon != null" class="custom-icon mini" :class="actionButtonIcon"/>
      <span v-if="!isMobile">{{ $t(actionButtonCode) }}</span>
      <span v-if="isMobile && actionButtonCodeMobile">{{ $t(actionButtonCodeMobile) }}</span>
    </el-button>

    <slot name="additional-items"/>
  </el-row>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import cloneDeep from "lodash/cloneDeep"
import moment from "moment"

export default {
  name: "TableActionRow",
  mixins: [generalMixin],
  props: {
    filterList: { type: Object, required: true },
    canFilterDate: { type: Boolean, default: false },
    actionButtonCode: { type: String },
    actionButtonCodeMobile: { type: String },
    actionButtonIcon: { type: String },
    handleAction: { type: Function, default: null },
    filterKeyNameList: { type: Array, default: null },
    isPositionAbsolute: { type: Boolean, default: true },
  },
  data() {
    return {
      showSearch: false,
      showDatePicker: false,
      listQuery: {},
      activeFilterNum: 0,
    }
  },
  methods: {
    toggleSearch() {
      if (this.showDatePicker) {
        this.toggleDatePicker()
      }
      this.showSearch = !this.showSearch
      if (this.showSearch == false && this.searchKeyword != "") {
        this.searchKeyword = ""
        this.handleFilterChange()
      }
    },

    toggleDatePicker() {
      if (this.isMobile == false) return
      if (this.showSearch) {
        this.toggleSearch()
      }
      this.showDatePicker = !this.showDatePicker
      if (this.showDatePicker == false && this.dateRange != null) {
        this.dateRange = null
        this.handleFilterChange()
      }
    },

    refreshActiveFilterNum() {
      let total = 0
      this.filterKeyNameList.forEach(fullKeyNameWithDot => {
        let fieldToCheck = null

        if (fullKeyNameWithDot.includes(".")) {
          fullKeyNameWithDot.split(".").forEach(keyName => {
            if (fieldToCheck == null) fieldToCheck = this.listQuery[keyName]
            else                      fieldToCheck = fieldToCheck[keyName]
          })
        } else {
          // without dot
          fieldToCheck = this.listQuery[fullKeyNameWithDot]
        }
        // console.log(fullKeyNameWithDot, fieldToCheck)
        if (  fieldToCheck != null 
          &&  fieldToCheck !== "" 
          &&  fieldToCheck !== "All" 
          &&  (fieldToCheck?.length != null ? fieldToCheck?.length > 0 : true)
          &&  fieldToCheck !== false 
          &&  fieldToCheck !== 0 ) {
          total++
        }
      })

      this.activeFilterNum = total
    },

    handleFilterChange() {
      this.$emit("onFilterChange", this.listQuery)
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    },
    dateRange: {
      get() { 
        return [moment(this.listQuery.dateFrom, this.DATE_FORMAT), moment(this.listQuery.dateTo, this.DATE_FORMAT)] 
      },
      set(value) {
        if (value != null) {
          this.listQuery.dateFrom = moment(value[0]).format(this.DATE_FORMAT)
          this.listQuery.dateTo = moment(value[1]).format(this.DATE_FORMAT)
        } else {
          this.listQuery.dateFrom = ""
          this.listQuery.dateTo = ""
        }
        this.handleFilterChange()
      }
    }
  },
  watch: {
    filterList: {
      handler(value) {
        this.listQuery = cloneDeep(value)
      }, deep: true
    },
    isMobile(value) {
      this.showDatePicker = !value
    }
  },
  mounted() {
    this.listQuery = cloneDeep(this.filterList)
    if (this.canFilterDate) {
      this.showDatePicker = !this.isMobile
    }
  }

}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@include icon-colors;

.table-action-row {
  &.position-absolute {
    z-index: 2;
    position: absolute;
    top: -4rem;
    right: 0;
  }

  .el-button:hover {
    .custom-icon {
      @extend .black-icon;
    }
  }
  >* {
    height: 2.5rem;
    border-radius: 4px;
    margin: auto 15px;
    &:first-child { margin-left: 0 }
    &:last-child { margin-right: 0 }
  }

  .toggleable-search {
    width: fit-content;
    input {
      transition: width 0.5s;
      height: 100%;
    }
    &.hide {
      input { 
        width: 20px;
        padding: 20px;
      }
      .el-input__prefix,
      .el-input__suffix {
        left: 10px;
      }
    }
    &.show {
      margin-right: 0;
      right: -18px;
      input { width: 386px }
      .el-input__suffix { right: 10px }
    }
    .el-input__prefix,
    .el-input__suffix {
      top: 10px;
    }
  }
  .search-button,
  .toggle-date-picker-button,
  .filter-button {
    z-index: 1;
    margin-right: 0.5rem;
    border-radius: 10%;
    padding: 0.5rem;
  }
  .el-date-editor {
    transition: width 0.5s;
    border: none;
    height: 2.5rem;
    .el-range-separator {
      width: auto;
    }
  }

  .filter-button {
    border: 1px solid #DCDFE6;
    .el-badge__content {
      right: 0;
      top: -7px;
      color: $primary;
      background-color: $white;
      border: 1px solid $primary;
    }
  }

}

.mobile {
  .table-action-row {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 0;
    height: 3.5rem;
    >* {
      margin: auto 5px;
      &:first-child { margin-left: 0 }
      &:last-child { margin-right: 0 }
    }
    .toggleable-search {
      &.show {
        margin-right: 7px;
        margin-left: 0;
        left: -2px;
        right: 0;
        input { width: calc(100% + 10px); }
      }
    }
    .middle-padding {
      flex-grow: 2;
      margin: 0;
    }
    .search-button {
      margin: auto 0;
    }
    .toggle-date-picker-button {
      margin-right: 0;
    }
    .el-date-editor {
      &.show {
        width: 100%;
        margin-left: 0;
        position: relative;
        left: -25px;
      }
      &.hide {
        width: 0;
        margin: auto 0;
        padding: 0;
        * {
          display: none;
        }
      }
      .el-range-separator {
        margin-top: 12px;
      }
    }
    .action-button {
      padding: 10px;
    }
  }
}

</style>